<template>
  <div>
    <x-text-field
        v-if="type === 'RADIUS' || type === 'RADIUS_ACCESS' || type === 'RADIUS_ACCOUNTING' || type === 'RAD-DYNAUTH'
           || type === 'LDAP' || (type === 'SSH' && auth === 'Password')"
        v-model="dataValue"
        type="password"
        :label="name"
        id="nocsConnectionc_password"
        name="c_password"
        tooltip="Hidden for security reasons."
        @input="emitInput"/>
  </div>
</template>
<script>
import XTextField from '@/components/basic/XTextField';

export default {
  components: {XTextField},
  props: ['result', 'fieldAttributes', 'field', 'value'],
  data() {
    return {
      dataValue: this.value,
    };
  },
  computed: {
    type() {
      return this.result.valueLine.c_type;
    },
    auth() {
      return this.result.valueLine.authMethod;
    },
    name() {
      let name = 'Password';
      if (this.type === 'RADIUS_ACCESS' || this.type === 'RADIUS_ACCOUNTING' || this.type === 'RAD-DYNAUTH' || this.type === 'RADIUS') {
        name = 'Shared Secret';
      }
      return name;
    },
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>